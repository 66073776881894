<template>
  <div class="iframe-container">
    <iframe :src="getURL" class="iframe-preview"></iframe>
  </div>
</template>

<script>
import { mapActions, mapGetters } from "vuex";

export default {
  data() {
    return {};
  },
  computed: {
    ...mapGetters(["getProfilePage"]),
    getURL() {
      console.log('this.getProfilePage?.link_to_doc: ', this.getProfilePage?.link_to_doc);
      return this.getProfilePage?.link_to_doc || "";
    },
  },
  methods: {
    ...mapActions(["fetchProfilePage"]),
  },
  watch: {
    getProfilePage(newVal) {
      if (!newVal || !newVal.link_to_doc) {
        this.$router.push("/");
      }
    },
  },
  async mounted() {
    const uuid = this.$route.params.uuid;
    await this.fetchProfilePage(uuid);
  },
};
</script>

<style scoped>
.iframe-container {
  position: fixed;
  top: 0;
  left: 0;
  width: 100vw;
  height: 100vh;
  background: white;
}

.iframe-preview {
  width: 100%;
  height: 100%;
  border: none;
}
</style>
